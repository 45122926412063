$.widget("ui.combobox", $.ui.autocomplete, {
    options: {
      minLength: 2,
      ajaxGetAll: { get: "all" },
      decoratorField: null
    },
  
    _create: function () {
      if (this.element.is("SELECT")) {
        this._selectInit();
        return;
      }
  
      $.ui.autocomplete.prototype._create.call(this);
  
      var input = this.element;
      input.addClass("ui-widget ui-widget-content ui-corner-left field custom-input")
        .attr("id", "autoCompIn")
        .click(function () { this.select(); });
  
      this.button = $("<button type='button'>&nbsp;</button>")
        .attr("tabIndex", -1)
        .attr("title", "Show All Items")
        .attr("id", "autoCompButton")
        .insertAfter(input)
        .button({
          disabled: true,
          icons: { primary: "ui-icon-triangle-1-s" },
          text: false
        })
        .removeClass("ui-corner-all")
        .addClass("ui-corner-right ui-button-icon custom-icon")
        .click(function (event) {
          var data = input.data("ui-combobox");
          clearTimeout(data.closing);
          if (!input.isFullMenu) {
            data._swapMenu();
          }
          input.combobox("widget").css("display", "block")
            .position($.extend({ of: input }, data.options.position ));
          input.focus();
          data._trigger("open");
          if (input.combobox("widget").zIndex() <= input.parent().zIndex()) {
            input.combobox("widget").zIndex(input.parent().zIndex() + 1);
          }
        })
        .hover(function (event) {
          $(this).removeClass("ui-state-hover");
          $(this).css("cursor", "default");
        });
  
      $(document).queue(function () {
        var data = input.data("ui-combobox");
        if (!data) { return; }
  
        if ($.isArray(data.options.source )) {
          $.ui.combobox.prototype._renderFullMenu.call(data, data.options.source);
        } else if (typeof data.options.source === "string") {
          $.getJSON(data.options.source, data.options.ajaxGetAll, function (source) {
            $.ui.combobox.prototype._renderFullMenu.call(data, source);
          });
        } else {
          $.ui.combobox.prototype._renderFullMenu.call(data, data.source());
        }
      });
  
      if (input.hasClass("error")) {
        this.button.addClass("custom-error");
      }
    },
  
    _renderFullMenu: function (source) {
      var self = this,
        input = this.element,
        ul = input.data("ui-combobox").menu.element,
        lis = [];
  
      source = this._normalize(source);
      input.data("ui-combobox").menuAll = input.data("ui-combobox").menu.element.clone(true).appendTo("body")[0];
  
      for (var i = 0; i < source.length; i++) {
        var item = source[i],
        label = item.label;
        if (this.options.decoratorField != null) {
          var d = item[this.options.decoratorField] || (item.option && $(item.option).attr(this.options.decoratorField));
          if (d != undefined)
          label = label + " " + d;
        }
        lis[i] = "<li class=\"ui-menu-item\" role=\"menuitem\"><a class=\"ui-corner-all\" tabindex=\"-1\">" + label + "</a></li>";
      }
      ul[0].innerHTML = lis.join("");
      this._resizeMenu();
  
      var items = $("li", ul).on("mouseover", "mouseout", function (event) {
        if (event.type == "mouseover") {
          self.menu.focus(event, $(this));
        } else {
          self.menu.blur();
        }
      });
  
      for (var i = 0; i < items.length; i++) {
        $(items[i]).data("ui-autocomplete-item", source[i]);
      }
  
      input.isFullMenu = true;
      this._swapMenu();
      self.button.button("enable");
      setTimeout(function () {
        $(document).dequeue();
      }, 0);
    },
  
    _renderItem: function (ul, item) {
      var label = item.label.replace(new RegExp(
        "(?![^&;]+;)(?!<[^<>]*)(" + $.ui.autocomplete.escapeRegex(this.term) +
        ")(?![^<>]*>)(?![^&;]+;)", "gi"), "<strong>$1</strong>");
      if (this.options.decoratorField != null) {
        var d = item[this.options.decoratorField] || (item.option && $(item.option).attr(this.options.decoratorField));
        if (d != undefined) {
          label = label + " " + d;
        }
      }
      return $("<li></li>")
        .data("ui-autocomplete-item", item)
        .append("<a>" + label + "</a>")
        .appendTo(ul);
    },
  
    close: function () {
      if (this.element.isFullMenu) {
        this._swapMenu();
      }
      $.ui.autocomplete.prototype.close.call(this);
    },
  
    destroy: function () {
      if (this.element.is("SELECT")) {
        this.input.removeData("ui-combobox", "menuAll");
        this.input.remove();
        this.element.removeData().show();
        return;
      }
      $.ui.autocomplete.prototype.destroy.call(this);
      this.element.removeClass("ui-widget ui-widget-content ui-corner-left");
      this.button.remove();
    },
  
    search: function (value, event) {
      var input = this.element;
      if (input.isFullMenu) {
        this._swapMenu();
      }
      $.ui.autocomplete.prototype.search.call(this, value, event);
    },
  
    _change: function (event) {
      if (!this.selectedItem) {
        var matcher = new RegExp("^" + $.ui.autocomplete.escapeRegex(this.element.val()) + '$', 'i'),
        match = $.grep(this.options.source, function (value) {
          return matcher.test(value.label);
        });
        if (match.length) {
          if (match[0].option != undefined) match[0].option.selected = true;
        } else {
          if (this.options.selectElement) {
            var firstItem = this.options.selectElement.children("option:first");
            this.element.val(firstItem.text());
            firstItem.prop("selected", true);
          } else {
            this.element.val("");
          }
          $(event.target).data("ui-combobox").previous = null;  // this will force a change event
        }
      }
      $.ui.autocomplete.prototype._change.call(this, event);
    },
  
    _swapMenu: function () {
      var input = this.element,
      data = input.data("ui-combobox"),
      tmp = data.menuAll;
      data.menuAll = data.menu.element.hide()[0];
      data.menu.element[0] = tmp;
      input.isFullMenu = !input.isFullMenu;
    },
  
    _selectInit: function () {
      var select = this.element,
      selectClass = select.attr("class"),
      selectStyle = select.attr("style"),
      selected = select.children(":selected"),
      value = $.trim(selected.text());
      select.hide();
      this.options.source = select.children("option").map(function () {
        return { label: $.trim(this.text), option: this };
      }).toArray();
      var userSelectCallback = this.options.select;
      var userSelectedCallback = this.options.selected;
      this.options.select = function (event, ui) {
        ui.item.option.selected = true;
        select.change();
        if (userSelectCallback) userSelectCallback(event, ui);
        if (userSelectedCallback) userSelectedCallback(event, ui);
      };
      this.options.selectElement = select;
      this.input = $("<input>").insertAfter(select).val(value);
      if (selectStyle) {
        this.input.attr("style", selectStyle);
      }
      if (selectClass) {
        this.input.attr("class", selectClass);
      }
      this.input.combobox(this.options);
    },
    inputbox: function () {
      if (this.element.is("SELECT")) {
        return this.input;
      } else {
        return this.element;
      }
    }
  });